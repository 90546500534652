import React from 'react';
import Layout from '../components/Layouts/layout';
import { Link } from 'gatsby'

const NotFoundPage = () => (
	<Layout title="404: Nenalezeno">
		<section className="page-header page-header-classic">
			<div className="container">
				<div className="row">
					<div className="col">
						<ul className="breadcrumb">
							<li>
								<Link to="/">Domů</Link>
							</li>
							<li className="active">404</li>
						</ul>
					</div>
				</div>
				<div className="row">
					<div className="col p-static">
						<span className="page-header-title-border visible" style={{width: 270.453}} />
						<h1 data-title-border="">404 - Stránka nenalezena</h1>
					</div>
				</div>
			</div>
		</section>

		<div className="container">
			<section className="http-error">
				<div className="row justify-content-center py-3">
					<div className="col-md-7 text-center">
						<div className="http-error-main">
							<h2>404!</h2>
							<p>Jejda, klíč nepasuje do zámku. Pokud potřebujete rychlou pomoc, zavolejte nám.</p>
						</div>
					</div>
					<div className="col-md-4 mt-4 mt-md-0">
						<h4 className="text-primary">S čím Vám rádi pomůžeme</h4>
						<ul className="list list-icons flex-column">
							<li className="nav-item"><i className="fas fa-check" /> Zabouchnuté dveře</li>
							<li className="nav-item"><i className="fas fa-check" /> Zamčené dveře</li>
							<li className="nav-item"><i className="fas fa-check" /> Zlomený klíč v zámku</li>
							<li className="nav-item"><i className="fas fa-check" /> Výměny kování</li>
							<li className="nav-item"><i className="fas fa-check" /> Odemknutí trezoru</li>
						</ul>
					</div>
				</div>
			</section>
		</div>
	</Layout>
);

export default NotFoundPage;
